import React from "react";

import Margin from "../Margin";

const Section = ({ children, withMargin = false, className = "" }) => {
    return (
        <Margin
            Component="section"
            className={className}
            {...(withMargin ? { top: 20 } : {})}
        >
            {children}
        </Margin>
    );
};

export default Section;
