import React from "react";

import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import Section from "../../../../shared/Section";

const StepSuccess = () => {
    return (
        <>
            <Section>
                <NewHeadline lookLike="two">Vielen Dank</NewHeadline>
                <p>
                    Vielen Dank für Deinen Antrag.
                    <br />
                    Dein persönlicher Ansprechpartner wird sich in den nächsten
                    Tagen mit Dir in Verbindung setzen.
                </p>
                <p>Wir wünschen Dir einen schönen Tag.</p>
                <Button to="/hoyer-card" centered>
                    Zur Übersicht
                </Button>
            </Section>
        </>
    );
};

export default StepSuccess;
