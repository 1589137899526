import React from "react";

import styled from "@emotion/styled";

import FormError from "../../../../shared/forms/FormError";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import TextAlign from "../../../../shared/TextAlign";
import useStore from "./store.js";

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    & > button {
        max-width: 400px;
    }
`;

const StepAccountType = () => {
    const { accountType, setAccountType, error } = useStore();
    return (
        <>
            <NewHeadline is="2" lookLike="3">
                Geschäftskunde oder Privatkunde?
            </NewHeadline>
            <p>Bist Du Geschäftskunde oder Privatkunde?</p>
            <ButtonWrapper>
                {[
                    { value: "company", name: "Geschäftskunde" },
                    { value: "private", name: "Privatkunde" },
                ].map((element, index) => (
                    <Button
                        key={index}
                        color={
                            accountType === element.value
                                ? "red"
                                : "greyOutline"
                        }
                        onClick={() => setAccountType(element.value)}
                        fullWidth
                    >
                        {element.name}
                    </Button>
                ))}
            </ButtonWrapper>
            {error && (
                <TextAlign
                    Component={FormError}
                    align="center"
                    className="is-danger"
                    message="Bitte wählen Sie aus ob sie Geschäftskunde oder Privatkunde sind."
                />
            )}
        </>
    );
};

export default StepAccountType;
