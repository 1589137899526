import React, { useState } from "react";

import styled from "@emotion/styled";

import { storageSession as session } from "../../../../../../utils/StorageHandler";
import Buttons from "../../../../../shared/Buttons";
import Margin from "../../../../../shared/Margin";
import Button from "../../../../../shared/NewForms/Button";
import Overlay from "../../../../../shared/Overlay";
import AddressForm from "../../StepAdressData/AddressForm";
import ContactPersonForm from "../../StepAdressData/ContactPersonForm";
import { default as useAddressStore } from "../../StepAdressData/store";
import StepPayment from "../../StepPayment";
import { default as usePaymentStore } from "../../StepPayment/store";
import StepRequirements from "../../StepRequirements";
import { default as useRequirementsStore } from "../../StepRequirements/store";

const OverlayContent = styled.div`
    max-height: 100%;
    overflow: auto;
    padding-bottom: 10px;
`;

const OverviewOverlay = ({ content, visible, setVisible }) => {
    const {
        dataIsValid: addressDataIsValid,
        resetLocalChanges: addressUndo,
        storeToServer: storeAddressToServer,
        storeToStorage: storeAddressToStorage,
    } = useAddressStore();
    const {
        dataIsValid: requirementsDataIsValid,
        resetLocalChanges: requirementsUndo,
    } = useRequirementsStore();
    const { dataIsValid: paymentDataIsValid, resetLocalChanges: paymentUndo } =
        usePaymentStore();

    const [checkoutData] = useState(session.getJson("hoyer-card:checkout"));

    const forms = {
        address: {
            component: <AddressForm />,
            undo: addressUndo,
            isValid: addressDataIsValid,
        },
        contact: {
            component: <ContactPersonForm />,
            undo: addressUndo,
            isValid: addressDataIsValid,
        },
        requirements: {
            component: <StepRequirements />,
            undo: requirementsUndo,
            isValid: requirementsDataIsValid,
        },
        payment: {
            component: <StepPayment />,
            undo: paymentUndo,
            isValid: paymentDataIsValid,
        },
    };

    if (!visible || !forms[content]) {
        return null;
    }

    const form = forms[content];

    const onSave = () => {
        if (form.isValid()) {
            storeAddressToServer(checkoutData.user_hash);
            storeAddressToStorage();
            setVisible(false);
        }
    };

    return (
        <Overlay visible={true}>
            <OverlayContent>
                {form.component}
                <Margin Component={Buttons} top="40" spaceBetween>
                    <Button
                        small
                        color="grey"
                        onClick={() => {
                            form.undo();
                            setVisible(false);
                        }}
                    >
                        Zurück
                    </Button>
                    <Button small onClick={onSave}>
                        Speichern
                    </Button>
                </Margin>
            </OverlayContent>
        </Overlay>
    );
};

export default OverviewOverlay;
