import React from "react";

import NewInput from "../../../../../shared/forms/NewInput";
import { SalutationSelect } from "../../../../../shared/forms/NewSelect";
import NewHeadline from "../../../../../shared/NewHeadline";
import Section from "../../../../../shared/Section";
import { default as useAccountTypeStore } from "../../StepAccountType/store";
import useStore from "../store";

const AddressForm = () => {
    const { fields, setDataValue, errors } = useStore();
    const { isCompany } = useAccountTypeStore();

    const handleChange = (event) => {
        setDataValue(event.target.name, event.target.value);
    };

    const hasErrorAt = (key) => {
        return errors.includes(key);
    };

    return (
        <>
            <Section>
                <NewHeadline is="2" lookLike="3">
                    Kontaktdaten
                </NewHeadline>
                {isCompany() && (
                    <NewInput
                        placeholder="Firma"
                        value={fields.company.value}
                        name="company"
                        onChange={handleChange}
                        validation="company"
                        forceError={hasErrorAt("company")}
                        withField
                    />
                )}

                <SalutationSelect
                    name="address_salutation"
                    onChange={handleChange}
                    value={fields.address_salutation.value}
                    fullwidth
                    withField
                />

                <NewInput
                    placeholder="Vorname"
                    value={fields.address_firstname.value}
                    name="address_firstname"
                    onChange={handleChange}
                    validation="name"
                    forceError={hasErrorAt("address_firstname")}
                    withField
                />
                <NewInput
                    placeholder="Nachname"
                    value={fields.address_lastname.value}
                    name="address_lastname"
                    onChange={handleChange}
                    validation="name"
                    forceError={hasErrorAt("address_lastname")}
                    withField
                />
            </Section>
            <Section withMargin>
                <NewInput
                    placeholder="E-Mail"
                    value={fields.address_email.value}
                    name="address_email"
                    onChange={handleChange}
                    validation="email"
                    forceError={hasErrorAt("address_email")}
                    withField
                />
                <NewInput
                    placeholder="Telefonnummer"
                    value={fields.address_phone.value}
                    name="address_phone"
                    onChange={handleChange}
                    validation="phone"
                    forceError={hasErrorAt("address_phone")}
                    withField
                />
                {!isCompany() && (
                    <NewInput
                        placeholder="Geburtsdatum"
                        value={fields.birthdate.value}
                        name="birthdate"
                        onChange={handleChange}
                        validation="date"
                        forceError={hasErrorAt("birthdate")}
                        withField
                    />
                )}
            </Section>
            <Section withMargin>
                <NewInput
                    placeholder="Straße und Hausnummer"
                    value={fields.address_street.value}
                    name="address_street"
                    onChange={handleChange}
                    validation="street"
                    forceError={hasErrorAt("address_street")}
                    withField
                />
                <NewInput
                    placeholder="Postleitzahl"
                    value={fields.address_zipcode.value}
                    name="address_zipcode"
                    onChange={handleChange}
                    validation="zipcode"
                    forceError={hasErrorAt("address_zipcode")}
                    withField
                />
                <NewInput
                    placeholder="Ort"
                    value={fields.address_city.value}
                    name="address_city"
                    onChange={handleChange}
                    validation="city"
                    forceError={hasErrorAt("address_city")}
                    withField
                />
            </Section>
            {isCompany() && (
                <Section withMargin>
                    <NewInput
                        placeholder="Steuer-Nr."
                        value={fields.vatNumber.value}
                        name="vatNumber"
                        onChange={handleChange}
                        validation="vatNumber"
                        forceError={hasErrorAt("vatNumber")}
                        withField
                    />
                    <NewInput
                        placeholder="Ust-ID"
                        value={fields.vatId.value}
                        name="vatId"
                        onChange={handleChange}
                        validation="vatId"
                        forceError={hasErrorAt("vatId")}
                        withField
                    />
                </Section>
            )}
        </>
    );
};

export default AddressForm;
