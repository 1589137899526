import create from "zustand";

import { storageSession as storage } from "../../../../../utils/StorageHandler";

export const storageKey = "hoyer-card:checkout:account-type";

const useStore = create((set, get) => ({
    accountType: storage.get(storageKey, null),
    setAccountType: (accountType) => {
        set({ accountType, ...(accountType !== null ? { error: false } : {}) });
    },
    isCompany: () => get().accountType === "company",
    error: false,
    /**
     * Check if the data is valid.
     */
    dataIsValid: () => {
        const accountType = get().accountType;
        const isValid = accountType !== null;

        set({ error: !isValid });

        if (isValid) {
            storage.set(storageKey, accountType);
        }

        return isValid;
    },
}));

export default useStore;
