import React from "react";

import styled from "@emotion/styled";

import Button from "../../../../shared/NewForms/Button";

const Wrapper = styled.div`
    display: flex;
    justify-content: ${(props) =>
        props.finalStep ? "center" : "space-between"};
    margin-top: 40px;
`;

const StepButtons = ({
    onNext,
    onPrevious,
    onFinalStep,
    finalStep = false,
}) => {
    return (
        <Wrapper finalStep={finalStep}>
            {finalStep ? (
                <Button
                    data-gtag="hoyer-card:request-form"
                    onClick={onFinalStep}
                >
                    Antrag absenden
                </Button>
            ) : (
                <>
                    {" "}
                    <Button color="grey" onClick={onPrevious}>
                        Zurück
                    </Button>
                    <Button onClick={onNext}>Weiter</Button>
                </>
            )}
        </Wrapper>
    );
};

export default StepButtons;
