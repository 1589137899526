import React from "react";

import { default as useAccountTypeStore } from "../StepAccountType/store";
import AddressForm from "./AddressForm";
import ContactPersonForm from "./ContactPersonForm";

const StepAddressData = () => {
    const { isCompany } = useAccountTypeStore();

    return (
        <>
            <AddressForm />
            {isCompany() && <ContactPersonForm />}
        </>
    );
};

export default StepAddressData;
