import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

import Checkbox from "../../../../shared/forms/Checkbox";
import FormError from "../../../../shared/forms/FormError";
import NewInput from "../../../../shared/forms/NewInput";
import NewSelect from "../../../../shared/forms/NewSelect";
import NewHeadline from "../../../../shared/NewHeadline";
import Section from "../../../../shared/Section";
import { default as useAccountTypeStore } from "../StepAccountType/store";
import useStore from "./store";

const SelectWrapper = styled.div`
    width: 100%;
    max-width: 400px;
`;

const StepRequirements = () => {
    const { fields, errors, setDataValue } = useStore();
    const [numberTil3p5tOptions, setNumberTil3p5tOptions] = useState([]);
    const [numberFrom3p5tOptions, setNumberFrom3p5tOptions] = useState([]);
    const [numberBusses, setNumberBusses] = useState([]);
    const { isCompany } = useAccountTypeStore();

    const handleChange = (event) => {
        setDataValue(event.target.name, event.target.value);
    };

    const hasErrorAt = (key) => {
        return errors.includes(key);
    };

    const numberOfCardsOptions = [];
    for (let i = 1; i <= 12; i++) {
        numberOfCardsOptions.push({ value: i, text: i });
    }

    /**
     * Returns an array with options from 0/1 to the given number.
     */
    const getOptionsForNumber = (number, includeZero = true) => {
        const start = includeZero ? 0 : 1;
        const options = [];
        for (let i = start; i <= number; i++) {
            options.push({ value: i, text: i });
        }
        return options;
    };

    useEffect(() => {
        setNumberTil3p5tOptions(
            getOptionsForNumber(fields.number_of_cards.value)
        );
        setNumberFrom3p5tOptions(
            getOptionsForNumber(fields.number_of_cards.value)
        );
        setNumberBusses(getOptionsForNumber(fields.number_of_cards.value));
    }, [
        fields.number_of_cards.value,
        fields.number_til3p5t.value,
        fields.number_from3p5t.value,
        fields.number_busses.value,
    ]);

    return (
        <>
            {isCompany() && (
                <>
                    <Section>
                        <NewHeadline is="2" lookLike="3">
                            Angaben zum Bedarf
                        </NewHeadline>

                        <SelectWrapper>
                            <NewSelect
                                name="number_of_cards"
                                value={fields.number_of_cards.value}
                                placeholder="Anzahl der benötigten Tankkarten"
                                options={numberOfCardsOptions}
                                onChange={handleChange}
                                withField
                            />
                        </SelectWrapper>
                    </Section>
                    <Section withMargin>
                        <NewHeadline is="2" lookLike="3">
                            Anzahl der Fahrzeuge
                        </NewHeadline>

                        <SelectWrapper>
                            <NewSelect
                                name="number_til3p5t"
                                value={fields.number_til3p5t.value}
                                placeholder="Transporter bis 3,5t"
                                options={numberTil3p5tOptions}
                                labelMinWidth="150"
                                onChange={handleChange}
                                withField
                            />
                            <NewSelect
                                name="number_from3p5t"
                                value={fields.number_from3p5t.value}
                                placeholder="LKW ab 3,5t"
                                options={numberFrom3p5tOptions}
                                labelMinWidth="150"
                                onChange={handleChange}
                                withField
                            />
                            <NewSelect
                                name="number_busses"
                                value={fields.number_busses.value}
                                placeholder="Busse"
                                options={numberBusses}
                                labelMinWidth="150"
                                onChange={handleChange}
                                withField
                            />
                        </SelectWrapper>
                        {hasErrorAt("number_of_cards") && (
                            <FormError message="Die ausgewählten Karten weichen von der Anzahl der benötigten Karten ab." />
                        )}
                    </Section>
                </>
            )}
            <Section withMargin>
                <NewHeadline is="2" lookLike="3">
                    Voraussichtlicher Bedarf
                </NewHeadline>
                <NewInput
                    value={fields.gas_per_month.value}
                    name="gas_per_month"
                    onChange={handleChange}
                    placeholder="Kraftstoff in Liter pro Monat"
                    validation="integer"
                    forceError={hasErrorAt("gas_per_month")}
                    withField
                />
                {isCompany() && (
                    <NewInput
                        value={fields.ad_blue_per_month.value}
                        name="ad_blue_per_month"
                        onChange={handleChange}
                        placeholder="AdBlue® in Liter pro Monat"
                        validation="integer"
                        forceError={hasErrorAt("ad_blue_per_month")}
                        withField
                    />
                )}
            </Section>
            {isCompany() ? (
                <Section withMargin>
                    <NewHeadline is="2" lookLike="3">
                        Service Bausteine
                    </NewHeadline>
                    <Checkbox
                        checked={fields.toll.value === true}
                        onClick={() => {
                            setDataValue("toll", !fields.toll.value);
                        }}
                        label="Maut-Service"
                    />
                    <div>
                        {fields.toll.value === true && (
                            <NewInput
                                name="toll_need"
                                value={fields.toll_need.value}
                                placeholder="Bedarf in Euro pro Monat"
                                onChange={handleChange}
                                validation="integer"
                                forceError={hasErrorAt("toll_need")}
                                withField
                            />
                        )}
                    </div>

                    <Checkbox
                        checked={fields.breakdown_service.value === true}
                        onClick={() => {
                            setDataValue(
                                "breakdown_service",
                                !fields.breakdown_service.value
                            );
                        }}
                        label="Pannenservice europaweit 24/7"
                    />
                    <Checkbox
                        checked={fields.mobility_service.value === true}
                        onClick={() => {
                            setDataValue(
                                "mobility_service",
                                !fields.mobility_service.value
                            );
                        }}
                        label="Mobilitätsservice"
                    />
                    <Checkbox
                        checked={fields.diesel_inspector.value === true}
                        onClick={() => {
                            setDataValue(
                                "diesel_inspector",
                                !fields.diesel_inspector.value
                            );
                        }}
                        label="Dieselinspektor"
                    />
                    <Checkbox
                        checked={fields.drivers_license_service.value === true}
                        onClick={() => {
                            setDataValue(
                                "drivers_license_service",
                                !fields.drivers_license_service.value
                            );
                        }}
                        label="Führerscheinkontrolle"
                    />
                    <Checkbox
                        checked={fields.travis.value === true}
                        onClick={() => {
                            setDataValue("travis", !fields.travis.value);
                        }}
                        label="Travis"
                    />
                </Section>
            ) : (
                <div>
                    <Section withMargin>
                        <NewHeadline is="2" lookLike="3">
                            Kraftstoffarten
                        </NewHeadline>
                        <Checkbox
                            checked={fields.diesel_adblue.value === true}
                            onClick={() => {
                                setDataValue(
                                    "diesel_adblue",
                                    !fields.diesel_adblue.value
                                );
                            }}
                            label="Dieselkraftstoffe, AdBlue®"
                        />
                        <Checkbox
                            checked={
                                fields.super95_e10_e85_super98.value === true
                            }
                            onClick={() => {
                                setDataValue(
                                    "super95_e10_e85_super98",
                                    !fields.super95_e10_e85_super98.value
                                );
                            }}
                            label="Super 95, E10, E85, Super 95, Super 98"
                        />

                        <Checkbox
                            checked={fields.lpg_cng.value === true}
                            onClick={() => {
                                setDataValue("lpg_cng", !fields.lpg_cng.value);
                            }}
                            label="LPG, CNG"
                        />
                    </Section>
                    <Section withMargin>
                        <NewHeadline is="2" lookLike="3">
                            Wunschpin
                        </NewHeadline>
                        <Checkbox
                            checked={fields.wants_custom_pin.value === true}
                            onClick={() => {
                                setDataValue(
                                    "wants_custom_pin",
                                    !fields.wants_custom_pin.value
                                );
                            }}
                            label="Ich möchte eine Wunschpin für meine Hoyer Card"
                        />
                        {fields.wants_custom_pin.value && (
                            <Section withMargin>
                                <p>
                                    Bitte gib hier eine 4-stellige PIN ein.
                                    Wähle keine einfach zu erratende PIN wie
                                    1111 oder 0815.
                                </p>
                                <NewInput
                                    name="custom_pin"
                                    value={fields.custom_pin.value}
                                    placeholder="PIN"
                                    validation="pin"
                                    onChange={handleChange}
                                    forceError={hasErrorAt("custom_pin")}
                                    withField
                                />
                            </Section>
                        )}
                    </Section>
                </div>
            )}
        </>
    );
};

export default StepRequirements;
