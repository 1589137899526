import create from "zustand";

import { apiFetch } from "../../../../../apiBindings";
import { storageSession as storage } from "../../../../../utils/StorageHandler";
import createFormErrorSlices from "../../../../../utils/store/formErrors";
import Validator from "../../../../../utils/validation/Validator";

const validator = new Validator();

const storageKey = "hoyer-card:checkout:payment";

/**
 * Creates the initial data. First grab the data from the storage. If there is
 * nothing, use the default values.
 */
const getInitialFields = () => {
    return storage.getJson(storageKey, {
        payment_account_holder: {
            value: "",
            validation: "company",
            required: true,
        },
        payment_bank_name: {
            value: "",
            validation: "company",
            required: true,
        },
        payment_iban: {
            value: "",
            validation: "iban",
            required: true,
        },
        payment_sepa_direct_granted: {
            value: false,
            validation: "isTrue",
            required: true,
        },
        payment_different_account_holder: {
            value: false,
        },
        payment_salutation: {
            value: "Herr",
        },
        payment_firstname: {
            value: "",
            validation: "name",
            required: false,
        },
        payment_lastname: {
            value: "",
            validation: "name",
            required: false,
        },
        payment_email: {
            value: "",
            validation: "email",
            required: false,
        },
        payment_phone: {
            value: "",
            validation: "phone",
            required: false,
        },
        payment_street: {
            value: "",
            validation: "street",
            required: false,
        },
        payment_zipcode: {
            value: "",
            validation: "zipcode",
            required: false,
        },
        payment_city: {
            value: "",
            validation: "city",
            required: false,
        },
    });
};

const initialFields = getInitialFields();

const useStore = create((set, get) => ({
    ...createFormErrorSlices(set, get),
    fields: { ...initialFields },
    data: () => {
        let data = { ...get().fields };
        Object.keys(data).forEach((key) => {
            data[key] = data[key].value;
        });
        return data;
    },
    setDataValue: (key, value) =>
        set((state) => ({
            fields: {
                ...state.fields,
                [key]: { ...state.fields[key], value: value },
            },
        })),
    setPaymentAddressRequired: (value) =>
        set((state) => ({
            fields: {
                ...state.fields,
                payment_firstname: {
                    ...state.fields["payment_firstname"],
                    required: value,
                },
                payment_lastname: {
                    ...state.fields["payment_lastname"],
                    required: value,
                },
                payment_email: {
                    ...state.fields["payment_email"],
                    required: value,
                },
                payment_phone: {
                    ...state.fields["payment_phone"],
                    required: value,
                },
                payment_street: {
                    ...state.fields["payment_street"],
                    required: value,
                },
                payment_zipcode: {
                    ...state.fields["payment_zipcode"],
                    required: value,
                },
                payment_city: {
                    ...state.fields["payment_city"],
                    required: value,
                },
            },
        })),
    /**
     * Check if the data is valid.
     */
    dataIsValid: () => {
        const state = get();
        const localErrors = [];
        let isValid = true;

        Object.keys(state.fields).forEach((key) => {
            if (state.fields[key].validation) {
                const fieldIsValid = validator.getValidator(
                    state.fields[key].validation,
                    state.fields[key].value,
                    state.fields[key].required ? true : false
                );
                if (!fieldIsValid()) {
                    isValid = false;
                    localErrors.push(key);
                }
            }
        });

        set({ errors: localErrors });

        // If the data is valid, store it in the local storage.
        if (isValid) {
            storage.setJson(storageKey, state.fields);
        }

        return isValid;
    },
    resetLocalChanges: () => {
        set({ fields: getInitialFields() });
    },
    storeToServer: (user_hash) => {
        apiFetch("/hoyer-card/checkout/payment", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({
                hash: user_hash,
                payment: get().data(),
            }),
        });
    },
    storeToStorage: () => {
        storage.setJson(storageKey, get().fields);
    },
}));

export default useStore;
