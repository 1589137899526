import React, { memo } from "react";

import cls from "../../../utils/cls";
import * as styles from "./style.module.scss";

const Buttons = memo(
    ({ children, spaceBetween = false, className = false }) => {
        return (
            <div
                className={cls(
                    className,
                    styles.Buttons,
                    React.Children.count(children) > 1 && styles.multiple,
                    spaceBetween && styles.spaceBetween
                )}
            >
                {children}
            </div>
        );
    }
);
Buttons.displayName = "Buttons";

export default Buttons;
