import create from "zustand";

import { storageSession as storage } from "../../../../../utils/StorageHandler";

const useStore = create((set, get) => ({
    agb: false,
    disclaimer: false,
    agbError: false,
    disclaimerError: false,
    setAgb: (value) => {
        set({ agb: value });
        set({ agbError: !value });
    },
    setDisclaimer: (value) => {
        set({ disclaimer: value });
        set({ disclaimerError: !value });
    },
    dataIsValid: () => {
        const state = get();
        if (state.agb === true && state.disclaimer === true) {
            return true;
        }

        set({ agbError: !state.agb, disclaimerError: !state.disclaimer });

        return false;
    },
    storeToStorage: () => {
        const state = get();
        storage.setJson("hoyer-card:checkout:overview", {
            agb: state.agb,
            agbError: state.agbError,
            disclaimer: state.disclaimer,
            disclaimerError: state.disclaimerError,
        });
    },
}));

export default useStore;
