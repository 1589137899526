import create from "zustand";

import { apiFetch } from "../../../../../apiBindings";
import { storageSession as storage } from "../../../../../utils/StorageHandler";
import Validator from "../../../../../utils/validation/Validator";
import { storageKey as accountTypeStorageKey } from "../StepAccountType/store";

const validator = new Validator();

const storageKey = "hoyer-card:checkout:address";

/**
 * Creates the initial data. First grab the data from the storage. If there is
 * nothing, use the default values.
 */
const getInitialFields = () => {
    return storage.getJson(storageKey, {
        company: {
            value: "",
            validation: "company",
            required: true,
        },
        address_salutation: {
            value: "Herr",
        },
        address_firstname: {
            value: "",
            validation: "name",
            required: true,
        },
        address_lastname: {
            value: "",
            validation: "name",
            required: true,
        },
        address_email: {
            value: "",
            validation: "email",
            required: true,
        },
        address_phone: {
            value: "",
            validation: "phone",
            required: true,
        },
        birthdate: {
            value: "",
            validation: "date",
            required: false,
        },
        address_street: {
            value: "",
            validation: "street",
            required: true,
        },
        address_zipcode: {
            value: "",
            validation: "zipcode",
            required: true,
        },
        address_city: {
            value: "",
            validation: "city",
            required: true,
        },
        vatId: {
            value: "",
            validation: "vatId",
        },
        vatNumber: {
            value: "",
            validation: "vatNumber",
        },
        contact_salutation: {
            value: "Herr",
        },
        contact_firstname: {
            value: "",
            validation: "name",
        },
        contact_lastname: {
            value: "",
            validation: "name",
        },
        contact_email: {
            value: "",
            validation: "email",
        },
        contact_phone: {
            value: "",
            validation: "phone",
        },
    });
};

const initialFields = getInitialFields();

const useStore = create((set, get) => ({
    fields: initialFields,
    data: () => {
        let data = { ...get().fields };
        Object.keys(data).forEach((key) => {
            data[key] = data[key].value;
        });
        return data;
    },
    dataForUndo: null,
    //setData: (registration) => set({ registration }),
    setDataValue: (key, value) =>
        set((state) => ({
            fields: {
                ...state.fields,
                [key]: { ...state.fields[key], value: value },
            },
        })),
    captureForUndo: () => {
        set({ dataForUndo: { ...get().fields } });
    },
    undo: () => {
        set({ fields: { ...get().dataForUndo }, dataForUndo: null });
    },
    /**
     * Check if the data is valid.
     */
    dataIsValid: () => {
        const state = get();
        const errors = [];
        let isValid = true;
        const accountType = storage.get(accountTypeStorageKey, null);

        Object.keys(state.fields).forEach((key) => {
            if (state.fields[key].validation) {
                let fieldIsValid = validator.getValidator(
                    state.fields[key].validation,
                    state.fields[key].value,
                    state.fields[key].required
                );

                // If user is not a company user, ignore the validation for the company field.
                if (key === "company" && accountType === "private") {
                    fieldIsValid = () => true;
                }

                if (!fieldIsValid()) {
                    isValid = false;
                    errors.push(key);
                }
            }
        });

        set({ errors: errors });

        // If the data is valid, store it in the local storage.
        if (isValid) {
            storage.setJson(storageKey, state.fields);
        }

        return isValid;
    },
    resetLocalChanges: () => {
        set({ fields: getInitialFields() });
    },
    errors: [],
    storeToServer: (user_hash) => {
        apiFetch(`/hoyer-card/checkout/address`, {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({
                hash: user_hash,
                address: get().data(),
            }),
        });
    },
    storeToStorage: () => {
        storage.setJson(storageKey, get().fields);
    },
}));

export default useStore;
