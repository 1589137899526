import React from "react";

import styled from "@emotion/styled";

import Button from "../../../../../shared/NewForms/Button";

const Wrapper = styled.div`
    display: flex;
`;
const Details = styled.div`
    flex-grow: 1;
`;
const ChangeButton = styled.div`
    font-size: 12px;
    text-transform: none;
`;

const ChangableDetail = ({ children, onClick }) => {
    return (
        <Wrapper>
            <Details>{children}</Details>
            <ChangeButton>
                <Button color="blue" text onClick={onClick}>
                    Ändern
                </Button>
            </ChangeButton>
        </Wrapper>
    );
};

export default ChangableDetail;
