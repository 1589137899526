import React from "react";

import cls from "../../../utils/cls";
import Container from "../Container";
import Margin from "../Margin";
import * as styles from "./style.module.scss";

const Overlay = ({ children, visible = false, boxClassName = null }) => {
    if (!visible) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={styles.background}></div>
            <div className={styles.overlay}>
                <Container
                    ultraSlim
                    className={cls(styles.overlayBox, boxClassName)}
                >
                    {children}
                </Container>
            </div>
        </React.Fragment>
    );
};

const Content = ({ children, showOverflow = false }) => (
    <div
        className={`${styles.content} ${
            showOverflow ? styles.showOverflow : ""
        }`}
    >
        {children}
    </div>
);
Overlay.Content = Content;

const Buttons = ({ children }) => (
    <Margin
        top="20"
        className={cls(
            styles.buttonWrapper,
            React.Children.count(children) === 1 && styles.singleChild
        )}
    >
        {children}
    </Margin>
);
Overlay.Buttons = Buttons;

export default Overlay;
