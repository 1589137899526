import React, { useEffect, useState } from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import { apiFetch } from "../../../../apiBindings";
import {
    storageSession as session,
    storageSession as storage,
} from "../../../../utils/StorageHandler";
import Box from "../../../shared/Box";
import Container from "../../../shared/Container";
import { navigate, Redirect } from "../../../shared/LinkComponent";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import Button from "../../../shared/NewForms/Button";
import NewHeadline from "../../../shared/NewHeadline";
import TextAlign from "../../../shared/TextAlign";
import StepAccountType from "./StepAccountType";
import { default as useAccountTypeStore } from "./StepAccountType/store";
import StepAddressData from "./StepAdressData";
import { default as useAddressStore } from "./StepAdressData/store";
import StepButtons from "./StepButtons";
import StepOverview from "./StepOverview";
import { default as useOverviewStore } from "./StepOverview/store";
import StepPayment from "./StepPayment";
import { default as usePaymentStore } from "./StepPayment/store";
import StepRequirements from "./StepRequirements";
import { default as useRequirementsStore } from "./StepRequirements/store";
import StepSuccess from "./StepSuccess";

const baseUrl = "/hoyer-card/antrag";
const storageFinishedKey = "hoyer-card:checkout:finished";

const CheckoutPage = () => {
    const {
        dataIsValid: accountTypeDataIsValid,
        isCompany,
        setAccountType,
    } = useAccountTypeStore();
    const {
        dataIsValid: addressDataIsValid,
        storeToServer: storeAddressToServer,
        storeToStorage: storeAddressToStorage,
        resetLocalChanges: resetLocalAddressChanges,
    } = useAddressStore();
    const {
        dataIsValid: requirementsDataIsValid,
        storeToServer: storeRequirementsToServer,
        storeToStorage: storeRequirementsToStorage,
        resetLocalChanges: resetLocalRequirementsChanges,
    } = useRequirementsStore();
    const {
        dataIsValid: paymentDataIsValid,
        storeToServer: storePaymentToServer,
        storeToStorage: storePaymentToStorage,
        resetLocalChanges: resetLocalPaymentChanges,
    } = usePaymentStore();
    const {
        dataIsValid: overviewDataIsValid,
        storeToStorage: storeOverviewToStorage,
    } = useOverviewStore();

    const steps = [
        {
            step: null,
            text: null,
            component: <StepAccountType />,
            url: "kundentyp",
            isValid: accountTypeDataIsValid,
        },
        {
            step: 1,
            text: "Kontaktdaten",
            component: <StepAddressData />,
            url: "kontaktdaten",
            isValid: addressDataIsValid,
        },
        {
            step: 2,
            text: "Bedarf",
            component: <StepRequirements />,
            url: "bedarf",
            isValid: requirementsDataIsValid,
        },
        {
            step: 2,
            text: "Zahlung",
            component: <StepPayment />,
            url: "zahlung",
            isValid: paymentDataIsValid,
        },
        {
            step: 3,
            text: "Übersicht",
            component: <StepOverview />,
            url: "uebersicht",
            isValid: overviewDataIsValid,
        },
        {
            step: null,
            text: null,
            component: <StepSuccess />,
            url: "erfolg",
        },
    ];

    const location = useLocation();
    const currentIndex = steps.findIndex((step) =>
        location.pathname.includes(step.url)
    );
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const [currentStep, setCurrentStep] = useState(steps[currentIndex]);
    const isLastStep = currentIndex === steps.length - 1;
    const isFinalStep = currentIndex === steps.length - 2;

    const [checkoutData, setCheckoutData] = useState(
        session.getJson("hoyer-card:checkout")
    );

    const isCompanyUrl = location.search.includes("is-company");
    useEffect(() => {
        if (isCompanyUrl) {
            setAccountType("company");
        }
    }, [isCompanyUrl]);

    /**
     * Go to the next step.
     */
    const nextStep = () => {
        if (!currentStep.isValid()) {
            return false;
        }
        let newIndex = currentIndex + 1;
        //HOY-3880: Always skip the payment step for now
        if (currentIndex === 2) {
            newIndex++;
        }

        void navigate(baseUrl + "/" + steps[newIndex].url);
    };

    /**
     * Init the checkout process.
     */
    const initCheckout = () => {
        storage.remove(storageFinishedKey);
        apiFetch("/hoyer-card/checkout/init", {
            method: "get",
        })
            .then((response) => response.json())
            .then((data) => {
                session.setJson("hoyer-card:checkout", data);
                setCheckoutData(data);
            });
    };

    /**
     * Submits the last checkout step
     */
    const finalStepSubmit = () => {
        if (!currentStep.isValid()) {
            return false;
        }
        nextStep();
        setSubmitting(true);
        apiFetch(`/hoyer-card/checkout/submit`, {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({
                hash: checkoutData.user_hash,
                isCompany: isCompany(),
            }),
        }).then(() => {
            storage.remove("hoyer-card:checkout");
            storage.remove("hoyer-card:checkout:account-type");
            storage.remove("hoyer-card:checkout:address");
            storage.remove("hoyer-card:checkout:payment");
            storage.remove("hoyer-card:checkout:requirements");
            storage.remove("hoyer-card:checkout:overview");
            storage.set(storageFinishedKey, true);
            resetLocalAddressChanges();
            resetLocalPaymentChanges();
            resetLocalRequirementsChanges();
            setSubmitting(false);
        });
    };

    /**
     * Go to the previous step
     */
    const previousStep = () => {
        if (currentIndex === 0) {
            void navigate("/hoyer-card");
        } else {
            let newIndex = currentIndex - 1;

            void navigate(baseUrl + "/" + steps[newIndex].url);
        }
    };

    // Handle the current step
    useEffect(() => {
        // Init the checkout, if not exists.
        if (session.get("hoyer-card:checkout") === null) {
            initCheckout();
        }

        const previousStepIndex = currentIndex - 1;

        // If we leave the address step do something with it
        if (steps[previousStepIndex]) {
            const previousUrl = steps[previousStepIndex].url;
            if (previousUrl === "kontaktdaten") {
                storeAddressToStorage();
                storeAddressToServer(checkoutData.user_hash);
            }
            if (previousUrl === "bedarf") {
                storeRequirementsToStorage();
                storeRequirementsToServer(checkoutData.user_hash);
            }
            if (previousUrl === "zahlung") {
                //HOY-3880: Always skip the payment step for now
                storeRequirementsToStorage();
                storeRequirementsToServer(checkoutData.user_hash);
                /*if (isCompany()) {
                    storeRequirementsToStorage();
                    storeRequirementsToServer(checkoutData.user_hash);
                } else {
                    storePaymentToStorage();
                    storePaymentToServer(checkoutData.user_hash);
                }*/
            }
            if (previousUrl === "uebersicht") {
                storeOverviewToStorage();
            }
        }
        // Set the current step, when the path changes.
        const currentStepLocal = steps.find((step) =>
            location.pathname.includes(step.url)
        );
        if (currentStepLocal) {
            // Forget that we finished the form if we are on the first step.
            if (currentIndex === 0) {
                storage.remove(storageFinishedKey);
            }
            setCurrentStep(currentStepLocal);
        }
    }, [location.pathname]);

    // If we cannot find a matching step for the url, redirect to the first step.
    if (currentIndex === -1) {
        return <Redirect to={baseUrl + "/" + steps[0].url} />;
    }

    if (currentStep) {
        return (
            <Container slim>
                <NewHeadline is={1} underline>
                    Hoyer Card Antrag
                </NewHeadline>
                {error ||
                (!submitting &&
                    isLastStep &&
                    storage.getBoolean(storageFinishedKey) === false) ? (
                    <Box>
                        <Box.Content>
                            <TextAlign Component="p" align="center">
                                Es ist leider ein Fehler aufgetreten. Versuche
                                es bitte später erneut.
                            </TextAlign>
                            <Button
                                centered
                                onClick={() => {
                                    setError(false);
                                    previousStep();
                                }}
                            >
                                Zurück
                            </Button>
                        </Box.Content>
                    </Box>
                ) : submitting ? (
                    <Box>
                        <LoadingIndicator>
                            Deine Daten werden übermittelt
                        </LoadingIndicator>
                    </Box>
                ) : (
                    <>{currentStep.component}</>
                )}

                {!isLastStep && (
                    <StepButtons
                        finalStep={isFinalStep}
                        onNext={nextStep}
                        onPrevious={previousStep}
                        onFinalStep={finalStepSubmit}
                    />
                )}
            </Container>
        );
    }

    return null;
};

export default CheckoutPage;
