import React from "react";

import NewInput from "../../../../../shared/forms/NewInput";
import { SalutationSelect } from "../../../../../shared/forms/NewSelect";
import NewHeadline from "../../../../../shared/NewHeadline";
import Section from "../../../../../shared/Section";
import useStore from "../store";

const ContactPersonForm = () => {
    const { fields, setDataValue, errors } = useStore();

    const handleChange = (event) => {
        setDataValue(event.target.name, event.target.value);
    };

    const hasErrorAt = (key) => {
        return errors.includes(key);
    };

    return (
        <Section withMargin>
            <NewHeadline is="2" lookLike="3">
                Ansprechpartner
            </NewHeadline>
            <SalutationSelect
                name="contact_salutation"
                onChange={handleChange}
                value={fields.contact_salutation.value}
                fullwidth
                withField
            />
            <NewInput
                id="contact_firstname"
                placeholder="Vorname"
                value={fields.contact_firstname.value}
                name="contact_firstname"
                onChange={handleChange}
                validation="name"
                forceError={hasErrorAt("contact_firstname")}
                withField
            />
            <NewInput
                id="contact_lastname"
                placeholder="Nachname"
                value={fields.contact_lastname.value}
                name="contact_lastname"
                onChange={handleChange}
                validation="name"
                forceError={hasErrorAt("contact_lastname")}
                withField
            />
            <NewInput
                id="contact_email"
                placeholder="E-Mail"
                value={fields.contact_email.value}
                name="contact_email"
                onChange={handleChange}
                validation="email"
                forceError={hasErrorAt("contact_email")}
                withField
            />
            <NewInput
                id="contact_phone"
                placeholder="Telefonnummer"
                value={fields.contact_phone.value}
                name="contact_phone"
                onChange={handleChange}
                validation="phone"
                forceError={hasErrorAt("contact_phone")}
                withField
            />
        </Section>
    );
};

export default ContactPersonForm;
