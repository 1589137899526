import React from "react";

import CheckoutPage from "../../../components/pages/HoyerCard/CheckoutPage";
import Robots from "../../../components/shared/Meta/Robots";
import Page from "../../../components/shared/Page";

const HoyerCardAntragPage = ({ params: { checkoutStep } }) => (
    <Page>
        <Robots index={false} />
        <CheckoutPage {...{ checkoutStep }} />
    </Page>
);
export default HoyerCardAntragPage;
