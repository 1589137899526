import create from "zustand";

import { apiFetch } from "../../../../../apiBindings";
import { storageSession as storage } from "../../../../../utils/StorageHandler";
import Validator from "../../../../../utils/validation/Validator";
import { default as useAccountTypeStore } from "../StepAccountType/store";

const validator = new Validator();

const storageKey = "hoyer-card:checkout:requirements";

/**
 * Creates the initial data. First grab the data from the storage. If there is
 * nothing, use the default values.
 */
const getInitialFields = () => {
    return storage.getJson(storageKey, {
        number_of_cards: {
            value: 1,
        },
        number_til3p5t: {
            value: 0,
        },
        number_from3p5t: {
            value: 0,
        },
        number_busses: {
            value: 0,
        },
        gas_per_month: {
            value: "",
            validation: "integer",
            required: true,
        },
        ad_blue_per_month: {
            value: "",
            validation: "integer",
        },
        toll: {
            value: false,
        },
        toll_need: {
            value: "",
            validation: "integer",
        },
        breakdown_service: {
            value: false,
        },
        mobility_service: {
            value: false,
        },
        diesel_inspector: {
            value: false,
        },
        drivers_license_service: {
            value: false,
        },
        travis: {
            value: false,
        },
        diesel_adblue: {
            value: false,
        },
        super95_e10_e85_super98: {
            value: false,
        },
        lpg_cng: {
            value: false,
        },
        wants_custom_pin: {
            value: false,
        },
        custom_pin: {
            value: "",
            validation: "pin",
        },
    });
};

const initialFields = getInitialFields();

const useStore = create((set, get) => ({
    fields: initialFields,
    data: () => {
        let data = { ...get().fields };
        Object.keys(data).forEach((key) => {
            data[key] = data[key].value;
        });
        return data;
    },
    setDataValue: (key, value) =>
        set((state) => ({
            fields: {
                ...state.fields,
                [key]: { ...state.fields[key], value: value },
            },
        })),
    /**
     * Check if the data is valid.
     */
    dataIsValid: () => {
        const isCompany = useAccountTypeStore.getState().isCompany();
        const state = get();
        const errors = [];
        let isValid = true;

        Object.keys(state.fields).forEach((key) => {
            if (state.fields[key].validation) {
                const fieldIsValid = validator.getValidator(
                    state.fields[key].validation,
                    state.fields[key].value,
                    state.fields[key].required ? true : false
                );
                if (!fieldIsValid()) {
                    isValid = false;
                    errors.push(key);
                }
            }
        });

        if (
            isCompany &&
            parseInt(state.fields.number_of_cards.value) !=
                parseInt(state.fields.number_til3p5t.value) +
                    parseInt(state.fields.number_from3p5t.value) +
                    parseInt(state.fields.number_busses.value)
        ) {
            isValid = false;
            errors.push("number_of_cards");
        }

        set({ errors: errors });

        // If the data is valid, store it in the local storage.
        if (isValid) {
            storage.setJson(storageKey, state.fields);
        }
        console.log(errors);
        return isValid;
    },
    resetLocalChanges: () => {
        set({ fields: getInitialFields() });
    },
    errors: [],
    storeToServer: (user_hash) => {
        apiFetch("/hoyer-card/checkout/requirements", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({
                hash: user_hash,
                requirements: get().data(),
            }),
        });
    },
    storeToStorage: () => {
        storage.setJson(storageKey, get().fields);
    },
}));

export default useStore;
