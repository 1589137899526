import React, { useEffect } from "react";

import Checkbox from "../../../../shared/forms/Checkbox";
import FormError from "../../../../shared/forms/FormError";
import NewInput from "../../../../shared/forms/NewInput";
import NewHeadline from "../../../../shared/NewHeadline";
import Section from "../../../../shared/Section";
//import DifferentAccountHolder from "./DifferentAccountHolder";
import useStore from "./store";

const StepPayment = () => {
    const {
        fields,
        hasErrorAt,
        addError,
        clearError,
        setDataValue,
        //setPaymentAddressRequired,
    } = useStore();

    const handleChange = (event) => {
        setDataValue(event.target.name, event.target.value);
    };

    /*const handleInvoiceCheckboxChange = () => {
        setDataValue(
            "payment_different_account_holder",
            !fields.payment_different_account_holder.value
        );
        setPaymentAddressRequired(
            !fields.payment_different_account_holder.value
        );
    };*/
    const handleSepaCheckboxChange = () => {
        setDataValue(
            "payment_sepa_direct_granted",
            !fields.payment_sepa_direct_granted.value
        );
    };

    return (
        <section>
            <NewHeadline>Zahlungsinformationen</NewHeadline>

            <Section>
                <NewInput
                    placeholder="Kontoinhaber"
                    value={fields.payment_account_holder.value}
                    name="payment_account_holder"
                    onChange={handleChange}
                    validation="company"
                    onError={() => addError("payment_account_holder")}
                    onErrorSolved={() => clearError("payment_account_holder")}
                    forceError={hasErrorAt("payment_account_holder")}
                    withField
                />
                <NewInput
                    placeholder="Kreditinstitut"
                    value={fields.payment_bank_name.value}
                    name="payment_bank_name"
                    onChange={handleChange}
                    validation="company"
                    onError={() => addError("payment_bank_name")}
                    onErrorSolved={() => clearError("payment_bank_name")}
                    forceError={hasErrorAt("payment_bank_name")}
                    withField
                />
                <NewInput
                    placeholder="IBAN"
                    value={fields.payment_iban.value}
                    name="payment_iban"
                    onChange={handleChange}
                    validation="iban"
                    onError={() => addError("payment_iban")}
                    onErrorSolved={() => clearError("payment_iban")}
                    forceError={hasErrorAt("payment_iban")}
                    withField
                />
                <p>
                    Ich ermächtige den Zahlungsempfänger Wilhelm Hoyer B.V. &
                    Co. KG, Zahlungen von meinem Konto mittels Lastschrift
                    einzuziehen. Zugleich weise ich mein Kreditinstitut an, die
                    vom Zahlungsempfänger Wilhelm Hoyer B.V. & Co. KG auf mein
                    Konto gezogenen Lastschriften einzulösen.
                    <br />
                    Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit
                    dem Belastungsdatum, die Erstattung des belasteten Betrages
                    verlangen. Es gelten dabei die mit meinem Kreditinstitut
                    vereinbarten Bedingungen.
                </p>
                <div>
                    <Checkbox
                        checked={fields.payment_sepa_direct_granted.value}
                        onClick={handleSepaCheckboxChange}
                        required
                        label="SEPA Lastschriftmandat erteilt"
                    />
                    {hasErrorAt("payment_sepa_direct_granted") && (
                        <FormError
                            className={""}
                            message={"Bitte erteilen Sie ein Mandat"}
                        />
                    )}
                </div>
                {/*<Checkbox
                    checked={fields.payment_different_account_holder.value}
                    onClick={handleInvoiceCheckboxChange}
                    label="Abweichender Kontoinhaber"
                />
                {fields.payment_different_account_holder.value === true && (
                    <DifferentAccountHolder />
                )}*/}
            </Section>
        </section>
    );
};

export default StepPayment;
